import React from "react";
import { useLocation } from "react-router-dom";

export default function GlobalFooter() {
  const location = useLocation();

  if (location.pathname.startsWith("/scan-ticket")) return null;

  return (
    <footer className="footer-global">
      Powered by
      <a href="https://www.bookzyme.com/" rel="noreferrer" target="_blank">
        Bookzy
      </a>
    </footer>
  );
}
